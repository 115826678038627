<template>
  <el-dialog
    width="600px"
    :title="$t('practice.form.share')"
    :visible.sync="visibleShareDialog"
    :before-close="
      () => {
        $emit('toggleVisibleShareDialog', false);
      }
    "
    center
  >
    <div style="text-align: center;" v-if="practice">
      <h6>{{ $t("practice.shareDialog.shareLink") }}</h6>
      <el-alert style="margin: 20px 0px" type="success" :closable="false">
        {{ $t("practice.shareDialog.clickCopyUrl1") }} <i class="fas fa-link" />
        <span v-if="take_status === EnumPracticeTakeStatuses.public">{{
          $t("practice.shareDialog.clickCopyUrl2")
        }}</span>
        <span v-else>{{
          $t("practice.shareDialog.shareLinkForStudentOrClasses")
        }}</span>
      </el-alert>
      <el-input disabled :value="practiceShareLink">
        <template slot="prepend">
          <el-tooltip
            class="item"
            :content="copied ? 'Copied!' : 'Copy'"
            placement="top"
            popper-class="tooltipColor"
          >
            <span @click="copyPracticeLink">
              <i class="fas fa-link" />
            </span>
          </el-tooltip>
        </template>
      </el-input>
      <input
        style="position: relative; top: -30px; z-index: -1"
        ref="practiceShareLinkInput"
        :value="practiceShareLink"
      />
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane
        :label="$t('practice.shareDialog.whoCanUseThePractice')"
        name="user"
      >
        <div style="text-align: center;" v-if="practice">
          <div style="text-align: left">
            <el-radio-group v-model="take_status">
              <el-radio :label="EnumPracticeTakeStatuses.public">
                <i class="fas fa-lock-open" />
                {{ $t("practice.shareDialog.public") }}
              </el-radio>
              <el-radio :label="EnumPracticeTakeStatuses.private">
                <i class="fas fa-lock" />
                {{ $t("practice.shareDialog.private") }}
              </el-radio>
              <el-radio :label="EnumPracticeTakeStatuses.unlisted">
                <i class="fas fa-link" />
                {{ $t("practice.shareDialog.unlisted") }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="take_status === EnumPracticeTakeStatuses.private">
            <h6>{{ $t("practice.shareDialog.shareForClassAndStudent") }}</h6>
            <h6>{{ $t("column.student") }}</h6>
            <StudentSelector @change="student => selectedStudent(student)" />
            <div style="display: flex; flex-wrap: wrap;">
              <div
                class="label"
                :key="student.id"
                v-for="student in students"
                @click="() => removeStudent(student.id)"
              >
                {{ student.name }}
              </div>
            </div>
            <div style="margin-top: 8px">
              <h6>{{ $t("column.class") }}</h6>
            </div>
            <SessionClassSelector
              @change="sessionClass => selectedSessionClass(sessionClass)"
            />
            <div style="display: flex; flex-wrap: wrap;">
              <div
                class="label"
                :key="sessionClass.id"
                v-for="sessionClass in sessionClasses"
                @click="() => removeSessionClass(sessionClass.id)"
              >
                {{ sessionClass.title }}
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('practice.shareDialog.whoCanEditThePractice')"
        name="editor"
      >
        <div style="text-align: center;" v-if="practice">
          <div style="text-align: left">
            <el-radio-group v-model="is_public_for_edit">
              <el-radio :label="true">
                <i class="fas fa-lock-open" />
                {{ $t("practice.shareDialog.public") }}
              </el-radio>
              <el-radio :label="false">
                <i class="fas fa-lock" />
                {{ $t("practice.shareDialog.private") }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="!is_public_for_edit">
            <h6>{{ $t("practice.shareDialog.shareForEditor") }}</h6>
            <TeacherSelector @change="editor => selectedEditor(editor)" />
            <div style="display: flex; flex-wrap: wrap;">
              <div
                class="label"
                :key="editor.id"
                v-for="editor in editors"
                @click="() => removeEditor(editor.id)"
              >
                {{ `${editor.first_name} ${editor.last_name}` }}
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <hr class="separate-line" />
    <div style="text-align: right">
      <el-button
        @click="
          () => {
            $emit('toggleVisibleShareDialog', false);
          }
        "
      >
        {{ $t("button.cancel") }}
      </el-button>
      <el-button @click="savePermission" type="primary">
        {{ $t("button.save") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import practiceApi from "@/apis/practices";
import fullPracticeApi from "@/apis/fullPractices";
import {
  StudentSelector,
  TeacherSelector,
  SessionClassSelector
} from "@/components/selector";
import { EnumPracticeTakeStatuses } from "@/enums";

export default {
  components: {
    StudentSelector,
    TeacherSelector,
    SessionClassSelector
  },
  props: {
    practice: {
      type: Object,
      default: null
    },
    visibleShareDialog: {
      type: Boolean,
      default: false
    },
    practiceType: {
      type: String,
      default: "practice"
    }
  },
  computed: {
    practiceShareLink() {
      return `https://ivy-way.com/${this.practiceType}/${this.practice.id}`;
    },
    EnumPracticeTakeStatuses() {
      return EnumPracticeTakeStatuses;
    }
  },
  watch: {
    practice() {
      this.initialPractice();
    }
  },
  data() {
    return {
      activeTab: "user",
      is_public_for_edit: false,
      take_status: EnumPracticeTakeStatuses.private,
      copied: false,
      students: [],
      editors: [],
      sessionClasses: []
    };
  },
  created() {
    this.initialPractice();
  },
  methods: {
    initialPractice() {
      if (this.practice === null) return;
      this.is_public_for_edit = this.practice.is_public_for_edit;
      this.take_status = this.practice.take_status;
      this.editors = this.practice.getEditors();
      this.students = this.practice.getTakeStudents();
      this.sessionClasses = this.practice.getTakeSessionClass();
    },
    selectedStudent(student) {
      if (this.students.map(({ id }) => id).includes(student.id)) return;
      this.students = [...this.students, student];
    },
    removeStudent(studentId) {
      this.students = this.students.filter(student => student.id !== studentId);
    },
    selectedEditor(editor) {
      if (this.editors.map(({ id }) => id).includes(editor.id)) return;
      this.editors = [...this.editors, editor];
    },
    removeEditor(editorId) {
      this.editors = this.editors.filter(editor => editor.id !== editorId);
    },
    selectedSessionClass(sessionClass) {
      if (this.sessionClasses.map(({ id }) => id).includes(sessionClass.id))
        return;
      this.sessionClasses = [...this.sessionClasses, sessionClass];
    },
    removeSessionClass(sessionClassId) {
      this.sessionClasses = this.sessionClasses.filter(
        sessionClass => sessionClass.id !== sessionClassId
      );
    },
    copyPracticeLink() {
      this.$refs.practiceShareLinkInput.focus();
      this.$refs.practiceShareLinkInput.select();
      window.document.execCommand("Copy");
      this.copied = true;
    },
    async savePermission() {
      try {
        const editors = this.editors.map(editor => ({
          permission_type: "edit",
          model_type: "App\\Entities\\User",
          model_id: editor.id
        }));
        const takeUsers = this.students.map(student => ({
          permission_type: "take",
          model_type: "App\\Entities\\User",
          model_id: student.user_id || student.id
        }));
        const takeClasses = this.sessionClasses.map(sessionClass => ({
          permission_type: "take",
          model_type: "App\\Entities\\SessionClass",
          model_id: sessionClass.id
        }));
        const postPermission = {
          is_public_for_edit: this.is_public_for_edit,
          take_status: this.take_status,
          permissions: [...editors, ...takeUsers, ...takeClasses]
        };
        switch (this.practiceType) {
          case "practice":
            await practiceApi.savePracticePermission(
              this.practice.id,
              postPermission
            );
            break;
          case "fullPractice":
            await fullPracticeApi.saveFullPracticePermission(
              this.practice.id,
              postPermission
            );
            break;
        }

        this.$emit("toggleVisibleShareDialog", false);
        this.$emit("reload");
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  margin: 8px 8px 8px 0px;
  padding: 4px;
  max-width: 100%;
  background-color: rgb(67, 160, 107);
  color: white;
  box-shadow: 1px 3px 6px #aaa;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  font-family: "Source Sans Pro", sans-serif;
}

.label:hover {
  background-color: red;
}
</style>
