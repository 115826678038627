import Echo from "laravel-echo";

window.io = require("socket.io-client");

const generatorWebSocket = token =>
  new Echo({
    broadcaster: "socket.io",
    host: process.env.VUE_APP_BACKEND_DOMAIN,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });

export { generatorWebSocket };
