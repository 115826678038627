<template>
  <el-table :data="results" style="width: 100%">
    <el-table-column fixed label="Student" width="140">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: scope.row.user_id }
          }"
        >
          {{ scope.row.user.name }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      :key="question.id"
      v-for="question in practice.exam.questions"
      :label="String(question.order)"
    >
      <template slot-scope="scope">
        <span
          :style="
            `color: ${
              getExamAnswers(question.id, scope.row.exam_answers).is_correct
                ? '#42a16a'
                : 'red'
            }`
          "
        >
          <b>
            {{
              parsingAnswer(
                question.question_type,
                question.id,
                getExamAnswers(question.id, scope.row.exam_answers).answers
              ).join(",")
            }}
          </b>
        </span>
        <span
          v-if="
            scope.row.is_finished === 1 &&
              parsingAnswer(
                question.question_type,
                question.id,
                getExamAnswers(question.id, scope.row.exam_answers).answers
              ).join(',') === ''
          "
        >
          <b>*</b>
        </span>
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="Session Score"
      width="120"
      v-if="visibleSessionResult"
    >
      <template slot-scope="scope">
        {{
          `${getSessionResult(scope.row.practices_correct_percents, practice.id).correct_questions_count}/${
            getSessionResult(scope.row.practices_correct_percents, practice.id).total_questions_count
          } (${getSessionResult(scope.row.practices_correct_percents, practice.id).correct_percent}%)`
        }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Time" width="80">
      <template slot-scope="scope">
        {{
          convertToMinuteTime(
            scope.row.other_status && scope.row.other_status.length > 0 ?
            scope.row.other_status[0].progress_time : 0
          )
        }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Progress" width="110">
      <template slot-scope="scope">
        <span style="color: red" v-if="!scope.row.is_finished">
          <i class="fas fa-times-circle warning-icon" />
          {{ $t("practice.inProgress") }}
        </span>
        <router-link
          v-else
          :to="
            `/${isFullPractice ? 'fullPractice' : 'practice'}/${id}/userExam/${scope.row.id}/result`
          "
        >
          {{ instant.formatGoogleTime(scope.row.updated_at) }}
          <i class="fas fa-check-circle" />
        </router-link>
      </template>
    </el-table-column>
    <el-table-column v-if="enabledDelete" fixed="right" label="Delete">
      <template slot-scope="scope">
        <span
          class="warning-icon"
          @click="() => $emit('deletePracticeUserExam', scope.row.id)"
        >
          <i class="fas fa-trash-alt" />
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { instant } from "@ivy-way/material";

export default {
  props: {
    id: {
      type: String,
      default: null
    },
    isFullPractice: {
      type: Boolean,
      default: false
    },
    results: {
      type: Array,
      default: () => []
    },
    practice: {
      type: Object,
      default: () => ({})
    },
    visibleSessionResult: {
      type: Boolean,
      default: false
    },
    enabledDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    instant() {
      return instant;
    }
  },
  methods: {
    getSessionResult(practices_correct_percents, practiceId) {
      return practices_correct_percents.find((practice) => (
        practice.practice_id === practiceId
      ));
    },
    getExamAnswers(questionId, examAnswers) {
      return examAnswers.find(answer => answer.exam_question_id === questionId);
    },
    parsingAnswer(questionType, questionId, answers) {
      switch (questionType) {
        case "toefl": {
          const {
            question: { options, type }
          } = this.practice.exam.questions.find(({ id }) => questionId === id);
          return answers.map(answer => {
            return options.find(option => String(option.id) === String(answer))
              .letter;
          });
        }
        default:
          return answers;
      }
    },
    convertToMinuteTime(seconds) {
      return `${String(Math.floor(seconds / 60)).padStart(2, "0")}:${String(
        seconds % 60
      ).padStart(2, "0")}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
