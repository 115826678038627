const getTestType = question_type => {
  switch (question_type) {
    case "App\\Entities\\SatQuestion":
      return "sat";
    case "App\\Entities\\ActQuestion":
      return "act";
    case "App\\Entities\\ToeflQuestion":
      return "toefl";
  }
  return "";
};

export default question => {
  switch (question.question_type) {
    case "App\\Entities\\ToeflQuestion":
      return {
        ...question,
        question_type: getTestType(question.question_type),
        question: {
          ...question.question,
          content: question.question.question,
          passage: {
            content: question.question.content.html
          },
          options: question.question.options.map(option => ({
            ...option,
            value: String(option.id),
            letter: option.title[0],
            title: option.title.slice(3)
          })),
          subject: { name: question.question.chapter.skill.name },
          answers: question.question.answers
            ? question.question.answers.map(answer => ({
              ...answer,
              answers: answer.options
            }))
            : []
        }
      };
    default:
      return {
        ...question,
        question_type: getTestType(question.question_type),
        question: {
          ...question.question,
          options: question.question.options.map(option => ({
            ...option,
            value: option.letter
          }))
        }
      };
  }
};
