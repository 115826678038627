var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.results}},[_c('el-table-column',{attrs:{"fixed":"","label":"Student","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'ProfileForAdmin',
          params: { id: scope.row.user_id }
        }}},[_vm._v(" "+_vm._s(scope.row.user.name)+" ")])]}}])}),_vm._l((_vm.practice.exam.questions),function(question){return _c('el-table-column',{key:question.id,attrs:{"label":String(question.order)},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:(`color: ${
            _vm.getExamAnswers(question.id, scope.row.exam_answers).is_correct
              ? '#42a16a'
              : 'red'
          }`)},[_c('b',[_vm._v(" "+_vm._s(_vm.parsingAnswer( question.question_type, question.id, _vm.getExamAnswers(question.id, scope.row.exam_answers).answers ).join(","))+" ")])]),(
          scope.row.is_finished === 1 &&
            _vm.parsingAnswer(
              question.question_type,
              question.id,
              _vm.getExamAnswers(question.id, scope.row.exam_answers).answers
            ).join(',') === ''
        )?_c('span',[_c('b',[_vm._v("*")])]):_vm._e()]}}],null,true)})}),(_vm.visibleSessionResult)?_c('el-table-column',{attrs:{"fixed":"right","label":"Session Score","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(`${_vm.getSessionResult(scope.row.practices_correct_percents, _vm.practice.id).correct_questions_count}/${ _vm.getSessionResult(scope.row.practices_correct_percents, _vm.practice.id).total_questions_count } (${_vm.getSessionResult(scope.row.practices_correct_percents, _vm.practice.id).correct_percent}%)`)+" ")]}}],null,false,691343702)}):_vm._e(),_c('el-table-column',{attrs:{"fixed":"right","label":"Time","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.convertToMinuteTime( scope.row.other_status && scope.row.other_status.length > 0 ? scope.row.other_status[0].progress_time : 0 ))+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"Progress","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.is_finished)?_c('span',{staticStyle:{"color":"red"}},[_c('i',{staticClass:"fas fa-times-circle warning-icon"}),_vm._v(" "+_vm._s(_vm.$t("practice.inProgress"))+" ")]):_c('router-link',{attrs:{"to":`/${_vm.isFullPractice ? 'fullPractice' : 'practice'}/${_vm.id}/userExam/${scope.row.id}/result`}},[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.updated_at))+" "),_c('i',{staticClass:"fas fa-check-circle"})])]}}])}),(_vm.enabledDelete)?_c('el-table-column',{attrs:{"fixed":"right","label":"Delete"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"warning-icon",on:{"click":() => _vm.$emit('deletePracticeUserExam', scope.row.id)}},[_c('i',{staticClass:"fas fa-trash-alt"})])]}}],null,false,2323875353)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }